import {
  getWithAuth,
  postWithAuth,
  putWithAuth,
  patchWithAuth,
} from "./ApiUtils";
import { getUrl } from "./Utils";

const BasePath = process.env.REACT_APP_API_BASE_PATH;

export async function getPredictionsPage(roundCode, playerId) {
  let queryParameters = [];

  if (roundCode) {
    queryParameters.push(`roundCode=${roundCode}`);
  }

  if (playerId) {
    queryParameters.push(`playerId=${playerId}`);
  }

  const url = getUrl(`${BasePath}/predictionsPage`, queryParameters);

  return await getWithAuth(url);
}

export async function getRoundLeaderboardPage(roundCode) {
  return await getWithAuth(
    `${BasePath}/leaderboardPage?roundCode=${roundCode}`
  );
}

export async function getLeaderboardPage() {
  return await getWithAuth(`${BasePath}/leaderboardPage`);
}

export async function getAdminPage() {
  return await getWithAuth(`${BasePath}/adminPage`);
}

export async function getAdminRoundsPage(tournamentCode) {
  return await getWithAuth(
    `${BasePath}/adminRoundsPage?tournamentCode=${tournamentCode}`
  );
}

export async function getAdminEditMatchesPage(roundCode) {
  return await getWithAuth(
    `${BasePath}/adminEditMatchesPage?roundCode=${roundCode}`
  );
}

export async function getTournamentLeaderboardPage(tournamentCode) {
  return await getWithAuth(
    `${BasePath}/leaderboardPage?tournamentCode=${tournamentCode}`
  );
}

export async function getPredictedTablePage(tournamentCode, playerId) {
  return await getWithAuth(
    `${BasePath}/predictedTablePage?tournamentCode=${tournamentCode}&playerId=${playerId}`
  );
}

export async function postUpdateScores(roundCode) {
  return await postWithAuth(`${BasePath}/rounds/${roundCode}/updateScores`);
}

export async function getNav() {
  return await getWithAuth(`${BasePath}/nav`);
}

export async function getMatchOverview(matchId) {
  return await getWithAuth(`${BasePath}/matches/${matchId}/overview`);
}

export async function getHomePage() {
  return await getWithAuth(`${BasePath}/homePage`);
}

export async function getUserPlayer() {
  return await getWithAuth(`${BasePath}/userPlayer`);
}

export async function getAdminFinalScoresPage(roundCode) {
  return await getWithAuth(
    `${BasePath}/adminFinalScoresPage?roundCode=${roundCode}`
  );
}

export async function getAdminPlayersPage() {
  return await getWithAuth(`${BasePath}/adminPlayersPage`);
}

export async function getCharityPage(tournamentCode) {
  return await getWithAuth(
    `${BasePath}/tournaments/${tournamentCode}/charityPage`
  );
}

export async function getBreakdownPage() {
  return await getWithAuth(`${BasePath}/breakdownPage`);
}

export async function getRoundBreakdownPage(roundCode) {
  return await getWithAuth(`${BasePath}/breakdownPage?roundCode=${roundCode}`);
}

export async function getCreatePlayerPage() {
  return await getWithAuth(`${BasePath}/createPlayerPage`);
}

export async function getAccountPage() {
  return await getWithAuth(`${BasePath}/accountPage`);
}

export async function postPlayer(playerName) {
  return await postWithAuth(`${BasePath}/players`, { name: playerName });
}

export async function postUserPlayer(playerName) {
  return await postWithAuth(`${BasePath}/userPlayers`, { name: playerName });
}

export async function postPremierLeagueGameweek(
  tournamentCode,
  gameweekNumber
) {
  return await postWithAuth(
    `${BasePath}/tournaments/${tournamentCode}/premierLeagueGameweeks/${gameweekNumber}`,
    null
  );
}

export async function postBotPredictions(roundCode) {
  return await postWithAuth(
    `${BasePath}/rounds/${roundCode}/makeBotPredictions`
  );
}

export async function postPredictions(
  playerId,
  matchId,
  homePrediction,
  awayPrediction
) {
  const payload = {
    playerId: playerId,
    predictions: [
      {
        matchId: matchId,
        predictedScore: {
          home: homePrediction,
          away: awayPrediction,
        },
      },
    ],
  };

  return await postWithAuth(`${BasePath}/predictions`, payload);
}

export async function patchRound(roundCode, isActive) {
  const payload = { isActive: isActive };
  return await patchWithAuth(`${BasePath}/rounds/${roundCode}`, payload);
}

export async function patchUser(userId, receivesReminders) {
  const payload = { receivesReminders: receivesReminders };
  return await patchWithAuth(`${BasePath}/users/${userId}`, payload);
}

export async function updateFinalScore(matchId, home, away) {
  const payload = {
    home: home,
    away: away,
  };

  return await putWithAuth(
    `${BasePath}/matches/${matchId}/finalScore`,
    payload
  );
}

export async function editMatchHomeTeam(matchId, homeTeamId) {
  const payload = { homeTeamId: homeTeamId };
  return await patchWithAuth(`${BasePath}/matches/${matchId}`, payload);
}

export async function editMatchAwayTeam(matchId, awayTeamId) {
  const payload = { awayTeamId: awayTeamId };
  return await patchWithAuth(`${BasePath}/matches/${matchId}`, payload);
}

export async function editMatchKickoff(matchId, kickoff) {
  const payload = { kickoff: kickoff };
  return await patchWithAuth(`${BasePath}/matches/${matchId}`, payload);
}
