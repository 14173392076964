export function localStorageGet(key) {
  const value = localStorage.getItem(key);

  if (value === null) {
    return null;
  }

  return JSON.parse(value);
}

export function localStorageSet(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}
