import React from "react";

export default function Name(props) {
  return (
    <>
      <span className={`d-none d-${props.breakpoint}-inline`}>
        {props.name}
      </span>
      <span className={`d-inline d-${props.breakpoint}-none`}>
        {props.shortName}
      </span>
    </>
  );
}
