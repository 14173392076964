import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import linq from "linq";

import SavePlaceholder from "../Components/SavePlaceholder";
import Main from "../Components/Main";
import Nav, { navPages } from "../Components/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { postPlayer } from "../PtoApi";
import { getAdminPlayersPage } from "../PtoApi";

const PlayerUserRow = (props) => {
  const extraClassName = props.playerUser.playerIsHuman
    ? null
    : "player-non-human";

  return (
    <tr>
      <td>
        <div className={`text-break ${extraClassName}`}>
          {props.playerUser.playerName}
        </div>
        <div className="text-break text-muted">{props.playerUser.playerId}</div>
      </td>
      <td>
        <div className="text-break">{props.playerUser.userEmailAddress}</div>
        <div className="text-break text-muted">{props.playerUser.userId}</div>
        <div className="text-break text-info">
          {props.playerUser.userReceivesReminders ? (
            <>
              <FontAwesomeIcon icon={faCheck} /> Reminders
            </>
          ) : null}
        </div>
      </td>
    </tr>
  );
};

const PlayerUsersSection = (props) => {
  const playerUserRows = linq
    .from(props.playerUsers)
    .orderBy((pu) => pu.playerName ?? pu.userEmailAddress)
    .select((pu) => (
      <PlayerUserRow key={pu.playerId ?? pu.userId} playerUser={pu} />
    ))
    .toArray();

  return (
    <table className="table table-striped table-sm table-hover">
      <thead>
        <tr>
          <th>Player</th>
          <th>User</th>
        </tr>
      </thead>
      <tbody>{playerUserRows}</tbody>
    </table>
  );
};

const CreatePlayerSection = () => {
  const [playerName, setPlayerName] = useState("");
  const [saveStatus, setSaveStatus] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setSaveStatus("SAVING");

      const response = await postPlayer(playerName);

      if (response.ok) {
        setSaveStatus("SAVED");
      } else {
        setSaveStatus("FAILED");
      }
    } catch {
      setSaveStatus("FAILED");
    }
  };

  const onPlayerNameChange = (e) => {
    setPlayerName(e.target.value);
  };

  return (
    <form className="form-inline" onSubmit={handleSubmit}>
      <div className="form-group mr-3">
        <input
          type="text"
          className="form-control"
          name="playerName"
          placeholder="New player name"
          onChange={onPlayerNameChange}
          value={playerName}
        />
      </div>
      <input type="submit" className="btn btn-primary mr-3" value="Create" />
      <SavePlaceholder status={saveStatus} />
    </form>
  );
};

const AdminPlayersPage = (props) => {
  const [playerUsers, setPlayerUsers] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setPlayerUsers(null);

      const response = await getAdminPlayersPage();
      const json = await response.json();

      setPlayerUsers(json.playerUsers);
    };

    fetchData();
  }, []);

  return (
    <>
      <Nav
        currentPage={navPages.ADMIN}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <h2>Players</h2>
        {playerUsers !== null ? (
          <PlayerUsersSection playerUsers={playerUsers} />
        ) : (
          <Skeleton />
        )}
        <CreatePlayerSection />
      </Main>
    </>
  );
};

export default AdminPlayersPage;
