import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Nav, { navPages } from "../Components/Nav";
import Main from "../Components/Main";
import { getAdminPage } from "../PtoApi";
import {
  getAdminRoundsUrl,
  getAdminEditMatchesUrl,
  getAdminFinalScoresUrl,
  getAdminPlayersUrl,
} from "../Urls";
import Skeleton from "react-loading-skeleton";

export default function AdminPage(props) {
  const [tournament, setTournament] = useState(null);
  const [round, setRound] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setTournament(null);
      setRound(null);

      const response = await getAdminPage();
      const json = await response.json();

      setTournament(json.tournament);
      setRound(json.round);
    };

    fetchData();
  }, []);

  return (
    <>
      <Nav
        currentPage={navPages.ADMIN}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <h2>Admin</h2>
        <ul>
          {tournament != null ? (
            <li>
              <Link to={getAdminRoundsUrl(tournament.code)}>Rounds</Link>
            </li>
          ) : (
            <Skeleton />
          )}
          {round != null ? (
            <li>
              <Link to={getAdminEditMatchesUrl(round.code)}>Edit Matches</Link>
            </li>
          ) : (
            <Skeleton />
          )}
          {round != null ? (
            <li>
              <Link to={getAdminFinalScoresUrl(round.code)}>Final Scores</Link>
            </li>
          ) : (
            <Skeleton />
          )}
          <li>
            <Link to={getAdminPlayersUrl()}>Players</Link>
          </li>
        </ul>
      </Main>
    </>
  );
}
