import React from "react";

export default function Spinner(props) {
  if (props.showSpinner) {
    return (
      <span className="spinner-border spinner-border-sm text-primary"></span>
    );
  }

  return null;
}
