import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faExclamationTriangle,
  faListOl,
  faQuestion,
  faTable,
} from "@fortawesome/free-solid-svg-icons";

import TournamentHeader from "../Components/TournamentHeader";
import Name from "../Components/Name";
import Nav, { navPages } from "../Components/Nav";
import Main from "../Components/Main";

import { getHomePage } from "../PtoApi";
import {
  getDefaultBreakdownUrl,
  getMatchUrl,
  getPredictedTableUrl,
  getDefaultLeaderboardUrl,
  getDefaultPredictionsUrl,
  getRoundPredictionsUrl,
} from "../Urls";
import Euro2024CharityAlert from "../Components/Euro2024CharityAlert";
import MatchScore from "../Components/MatchScore";
import TeamDisplay from "../Components/TeamDisplay";

const MatchItem = (props) => {
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="row mb-2">
        <div className="col-4">
          <TeamDisplay
            team={props.match.homeTeam}
            teamName={props.match.homeTeam.shortName}
          />
        </div>
        <div className="col-4 text-center">
          <MatchScore
            currentScore={props.match.currentScore}
            finalScore={props.match.finalScore}
            kickoff={props.match.kickoff}
          />
          <div>
            <Link to={getMatchUrl(props.match.id)}>View</Link>
          </div>
        </div>
        <div className="col-4">
          <TeamDisplay
            team={props.match.awayTeam}
            teamName={props.match.awayTeam.shortName}
          />
        </div>
      </div>
    </div>
  );
};

const MatchesPanel = (props) => {
  return (
    <div className="row mb-3">
      {props.matches.map((m) => (
        <MatchItem key={m.id} match={m} />
      ))}
    </div>
  );
};

const FeatureItem = (props) => {
  return (
    <div className="col-md-6 d-flex flex-row mb-4">
      <div className="mx-2 my-1">
        <FontAwesomeIcon icon={props.icon} size="3x" fixedWidth />
      </div>

      <div className="">
        <h4>{props.title}</h4>
        {props.content}
      </div>
    </div>
  );
};

const PredictionsWarning = (props) => {
  if (props.count === 0) {
    return null;
  }

  return (
    <span className={`text-${props.severity}`}>
      <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2" />
      <abbr
        title={`${props.count} ${props.adjective} prediction${
          props.count === 1 ? "" : "s"
        }`}
      >
        {props.count}
      </abbr>
    </span>
  );
};

const PredictionsPanel = (props) => {
  const content = props.homePageData.player ? (
    <ul className="rounds">
      {props.homePageData.activeRoundMissingPredictions.map((x) => (
        <li key={x.round.code}>
          <Link
            to={getRoundPredictionsUrl(x.round.code, null)}
          >
            <Name
              name={x.round.name}
              shortName={x.round.shortName}
              breakpoint="sm"
            />
          </Link>
          <PredictionsWarning
            count={x.urgentPredictions}
            severity="danger"
            adjective="urgent"
          />
          <PredictionsWarning
            count={x.missingPredictions}
            severity="warning"
            adjective="missing"
          />
        </li>
      ))}
    </ul>
  ) : (
    <>
      Click to start entering predictions for{" "}
      {props.homePageData.currentRound.name}.
    </>
  );

  return (
    <FeatureItem
      icon={faQuestion}
      title={<Link to={getDefaultPredictionsUrl()}>Predictions</Link>}
      content={content}
    />
  );
};

export default function HomePage(props) {
  const [homePageData, setHomePageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setHomePageData(null);

      const response = await getHomePage();
      const responseJson = await response.json();

      setHomePageData(responseJson);
    };

    fetchData();
  }, []);

  return (
    <>
      <Nav
        currentPage={navPages.HOME}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <div className="mb-3">
          {homePageData !== null ? (
            <TournamentHeader tournament={homePageData.currentTournament} />
          ) : (
            <Skeleton />
          )}
        </div>
        {homePageData !== null &&
        homePageData.currentTournament.code === "euro2024" ? (
          <Euro2024CharityAlert />
        ) : null}
        <div className="row">
          <div className="col">
            {homePageData !== null ? (
              <MatchesPanel matches={homePageData.currentMatches} />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        <div className="row">
          {homePageData !== null ? (
            <PredictionsPanel homePageData={homePageData} />
          ) : (
            <Skeleton />
          )}
          {homePageData !== null ? (
            <FeatureItem
              icon={faListOl}
              title={<Link to={getDefaultLeaderboardUrl()}>Leaderboard</Link>}
              content="Click to see how your points compare with everyone else's."
            />
          ) : (
            <Skeleton />
          )}
          {homePageData !== null ? (
            <FeatureItem
              icon={faTable}
              title={<Link to={getDefaultBreakdownUrl()}>Breakdown</Link>}
              content={
                <>
                  Click to see the results for {homePageData.currentRound.name},
                  broken down by player and match.
                </>
              }
            />
          ) : (
            <Skeleton />
          )}
          {homePageData !== null ? (
            <FeatureItem
              icon={faChartBar}
              title="More stats"
              content={
                <ul>
                  {homePageData.player &&
                  homePageData.currentTournament.isLeague ? (
                    <li>
                      <Link
                        to={getPredictedTableUrl(
                          homePageData.currentTournament.code,
                          homePageData.player.id
                        )}
                      >
                        Predicted table
                      </Link>
                    </li>
                  ) : null}
                </ul>
              }
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </Main>
    </>
  );
}
