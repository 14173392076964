import React, { useEffect, useState } from "react";
import linq from "linq";
import Skeleton from "react-loading-skeleton";

import {
  updateFinalScore,
  postUpdateScores,
  getAdminFinalScoresPage,
} from "../PtoApi";
import RoundsDropdown from "../Components/RoundsDropdown";
import TournamentsDropdown from "../Components/TournamentsDropdown";
import MatchDataEntry from "../Components/MatchDataEntry";
import { groupMatchesByDate } from "../Utils";
import SavePlaceholder from "../Components/SavePlaceholder";
import { formatDate } from "../Strings";
import Nav, { navPages } from "../Components/Nav";
import { getAdminFinalScoresUrl } from "../Urls";
import Main from "../Components/Main";

const UpdateScores = (props) => {
  const [saveStatus, setSaveStatus] = useState(null);
  const [error, setError] = useState(null);

  const onUpdate = async () => {
    try {
      setSaveStatus("SAVING");
      setError(null);

      const response = await postUpdateScores(props.round.code);

      if (response.ok) {
        setSaveStatus("SAVED");
        setError(null);
      } else {
        const json = await response.json();

        setSaveStatus("FAILED");
        setError(json.errors[0].message);
      }
    } catch (err) {
      setSaveStatus("FAILED");
      setError(err.message);
    }

    await props.onUpdate();
  };

  return (
    <div>
      <button
        className="btn btn-primary mr-3"
        onClick={async (e) => await onUpdate()}
      >
        Update scores
      </button>
      <SavePlaceholder status={saveStatus} error={error} />
    </div>
  );
};

const MatchGroup = (props) => {
  const onSave = async (matchId, homeScore, awayScore) => {
    return await updateFinalScore(matchId, homeScore, awayScore);
  };

  const items = linq
    .from(props.matches)
    .orderBy((m) => m.kickoff)
    .thenBy((m) => m.homeTeam.name)
    .select((m) => (
      <MatchDataEntry
        onSave={onSave}
        match={m}
        score={m.finalScore}
        key={m.id}
        isEditable={true}
      />
    ))
    .toArray();

  return (
    <>
      <h4 className="mt-4 mb-3">{formatDate(props.date)}</h4>
      {items}
    </>
  );
};

const AdminFinalScoresPage = (props) => {
  const [tournaments, setTournaments] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [rounds, setRounds] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);
  const [matches, setMatches] = useState(null);

  const load = async () => {
    setTournaments(null);
    setSelectedTournament(null);
    setRounds(null);
    setSelectedRound(null);
    setMatches(null);

    const response = await getAdminFinalScoresPage(props.roundCode);

    const json = await response.json();

    setTournaments(json.tournaments);
    setSelectedTournament(json.selectedTournament);
    setRounds(json.rounds);
    setSelectedRound(json.selectedRound);
    setMatches(json.matches);
  };

  useEffect(() => {
    load();
  }, [props.roundCode]);

  return (
    <>
      <Nav
        currentPage={navPages.ADMIN}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <div className="row">
          <div className="col-6 mb-3">
            {tournaments && selectedTournament ? (
              <TournamentsDropdown
                tournaments={tournaments}
                tournament={selectedTournament}
                getLinkDestination={(t) =>
                  getAdminFinalScoresUrl(t.currentRoundCode)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
          <div className="col-6 mb-3">
            {rounds && selectedRound ? (
              <RoundsDropdown
                rounds={rounds}
                round={selectedRound}
                includeOverall={false}
                getRoundLinkDestination={(roundCode) =>
                  getAdminFinalScoresUrl(roundCode)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        {selectedRound ? (
          <UpdateScores
            round={selectedRound}
            onUpdate={async () => await load()}
          />
        ) : (
          <Skeleton />
        )}
        {matches ? (
          linq
            .from(groupMatchesByDate(matches))
            .select((g) => (
              <MatchGroup matches={g.matches} date={g.date} key={g.date} />
            ))
            .toArray()
        ) : (
          <Skeleton count={5} />
        )}
      </Main>
    </>
  );
};

export default AdminFinalScoresPage;
