import React from "react";
import { Link } from "react-router-dom";
import { getAboutUrl } from "../Urls";

const Separator = () => {
  return <span className="mx-2">|</span>;
};

export default function Footer() {
  return (
    <footer className="mt-auto">
      <nav className="text-muted py-3 bg-primary">
        <div className="container">
          <span>PTO &copy; 2022</span>
          <Separator />
          <Link to={getAboutUrl()}>About</Link>
        </div>
      </nav>
    </footer>
  );
}
