import React, { useEffect, useState } from "react";
import linq from "linq";
import { getPredictedTablePage } from "../PtoApi";
import TeamImage from "../Components/TeamImage";
import TournamentsDropdown from "../Components/TournamentsDropdown";
import PlayersDropdown from "../Components/PlayersDropdown";
import Name from "../Components/Name";
import { getPredictedTableUrl } from "../Urls";
import Nav from "../Components/Nav";
import Skeleton from "react-loading-skeleton";
import Main from "../Components/Main";

function TablePlacing(props) {
  const placing = props.placing;

  return (
    <tr>
      <td>{placing.position}</td>
      <td className="text-center">
        <TeamImage team={placing.team} height="25" />
      </td>
      <td>
        <Name
          breakpoint="sm"
          name={placing.team.name}
          shortName={placing.team.shortName}
        />
      </td>
      <td>{placing.played}</td>
      <td>{placing.goalDifference}</td>
      <td>{placing.points}</td>
    </tr>
  );
}

function Table(props) {
  const placings = linq
    .from(props.placings)
    .select((p) => <TablePlacing placing={p} key={p.position} />)
    .toArray();

  return (
    <table className="table table-sm table-hover table-striped">
      <tbody>
        <tr>
          <th>#</th>
          <th>&nbsp;</th>
          <th>Team</th>
          <th>PLD</th>
          <th>GD</th>
          <th>PTS</th>
        </tr>
        {placings}
      </tbody>
    </table>
  );
}

export default function PredictedTablePage(props) {
  const [responseJson, setResponseJson] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setResponseJson(null);

      const response = await getPredictedTablePage(
        props.tournamentCode,
        props.playerId
      );

      setResponseJson(await response.json());
    };

    fetchData();
  }, [props.tournamentCode, props.playerId]);

  return (
    <>
      <Nav onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        <div className="row">
          <div className="col mb-3">
            {responseJson ? (
              <TournamentsDropdown
                tournaments={responseJson.tournaments}
                tournament={responseJson.tournament}
                getLinkDestination={(t) =>
                  getPredictedTableUrl(t.code, props.playerId)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
          <div className="col mb-3">
            {responseJson ? (
              <PlayersDropdown
                players={responseJson.players}
                player={responseJson.player}
                getLinkDestination={(playerId) =>
                  getPredictedTableUrl(props.tournamentCode, playerId)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>

        {responseJson ? <Table placings={responseJson.table} /> : <Skeleton />}
      </Main>
    </>
  );
}
