import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";

import SavePlaceholder from "../Components/SavePlaceholder";
import Nav, { navPages } from "../Components/Nav";
import Main from "../Components/Main";

import { changePassword } from "../PtoAuth";
import { getAccountPage, patchUser } from "../PtoApi";
import { getPasswordErrorMessage } from "../Utils";

class ChangePasswordSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      canChangePassword: false,
      changePasswordStatus: null,
      changePasswordError: null,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      changePasswordStatus: "SAVING",
      changePasswordError: null,
    });

    try {
      await changePassword(this.state.oldPassword, this.state.newPassword);

      this.setState({ changePasswordStatus: "SAVED" });
    } catch (err) {
      console.log(err);
      this.setState({
        changePasswordStatus: "FAILED",
        changePasswordError: getPasswordErrorMessage(err),
      });
    }
  };

  setCanChangePassword(oldPassword, newPassword) {
    this.setState({ canChangePassword: oldPassword && newPassword });
  }

  onOldPasswordChange = (e) => {
    const oldPassword = e.target.value;
    this.setState({ oldPassword: oldPassword });

    this.setCanChangePassword(oldPassword, this.state.newPassword);
  };

  onNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    this.setState({ newPassword: newPassword });

    this.setCanChangePassword(this.state.oldPassword, newPassword);
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-8 col-lg-6">
          <div className="card">
            <div className="card-header">
              <FontAwesomeIcon icon={faKey} className="mr-3 align-middle" />
              <button
                className="btn btn-link p-0 text-left align-middle"
                type="button"
                data-toggle="collapse"
                data-target="#changePasswordCollapse"
                aria-expanded="true"
                aria-controls="changePasswordCollapse"
              >
                Change Password
              </button>
            </div>

            <div className="collapse" id="changePasswordCollapse">
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label>Old password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="oldPassword"
                      onChange={(e) => this.onOldPasswordChange(e)}
                      value={this.state.oldPassword}
                    />
                  </div>
                  <div className="form-group">
                    <label>New password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="newPassword"
                      onChange={(e) => this.onNewPasswordChange(e)}
                      value={this.state.newPassword}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      className="btn btn-primary mr-3"
                      value="Change password"
                      disabled={!this.state.canChangePassword}
                    />
                    <SavePlaceholder status={this.state.changePasswordStatus} />
                  </div>
                  <div className="form-group text-danger">
                    {this.state.changePasswordError}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const RemindersSection = (props) => {
  const [receivesReminders, setReceivesReminders] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setReceivesReminders(props.receivesReminders);
  }, [props]);

  const onReceivesRemindersChange = async (event) => {
    setSaveStatus("SAVING");

    const value = event.target.checked;

    try {
      await patchUser(props.userId, value);
      setReceivesReminders(value);
      setSaveStatus("SAVED");
    } catch (err) {
      setSaveStatus("FAILED");
      setError(err.message);
    }
  };

  return (
    <div className="form-group">
      {receivesReminders !== null ? (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            id="receivesRemindersCheckbox"
            className="custom-control-input"
            defaultChecked={receivesReminders}
            onChange={async (e) => await onReceivesRemindersChange(e)}
          />
          <label
            className="custom-control-label mr-3"
            htmlFor="receivesRemindersCheckbox"
          >
            Send me reminder emails if I have forgotten to enter predictions
          </label>
          <SavePlaceholder status={saveStatus} error={error} />
        </div>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

const EmailAddressSection = (props) => {
  return (
    <div className="form-group">
      {props.emailAddress !== null ? (
        <>
          <FontAwesomeIcon icon={faUser} className="mr-3" />
          {props.emailAddress}
        </>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};

const AccountPage = (props) => {
  const [receivesReminders, setReceivesReminders] = useState(null);
  const [userId, setUserId] = useState(null);
  const [emailAddress, setEmailAddress] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setReceivesReminders(null);
      setUserId(null);
      setEmailAddress(null);

      const response = await getAccountPage();
      const json = await response.json();

      setReceivesReminders(json.receivesReminders);
      setUserId(json.userId);
      setEmailAddress(json.emailAddress);
    };

    fetchData();
  }, []);

  return (
    <>
      <Nav
        currentPage={navPages.ACCOUNT}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <h2>Account</h2>
        <EmailAddressSection emailAddress={emailAddress} />
        <RemindersSection
          receivesReminders={receivesReminders}
          userId={userId}
        />
        <ChangePasswordSection />
      </Main>
    </>
  );
};

export default AccountPage;
