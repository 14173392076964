import { getCurrentSessionOrNull } from "./PtoAuth";

export async function getWithAuth(url) {
  let currentSessionOrNull = await getCurrentSessionOrNull();

  if (currentSessionOrNull === null) {
    return null;
  }

  let response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + currentSessionOrNull.idToken.jwtToken,
      "Website-Version": process.env.REACT_APP_BUILD_NUMBER,
    },
  });

  return response;
}

export async function postWithAuth(url, payload) {
  let currentSessionOrNull = await getCurrentSessionOrNull();

  if (currentSessionOrNull === null) {
    return null;
  }

  let response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + currentSessionOrNull.idToken.jwtToken,
      "Content-Type": "application/json",
      "Website-Version": process.env.REACT_APP_BUILD_NUMBER,
    },
    body: payload ? JSON.stringify(payload) : null,
  });

  return response;
}

export async function patchWithAuth(url, payload) {
  let currentSessionOrNull = await getCurrentSessionOrNull();

  if (currentSessionOrNull === null) {
    return null;
  }

  let response = await fetch(url, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + currentSessionOrNull.idToken.jwtToken,
      "Content-Type": "application/json",
      "Website-Version": process.env.REACT_APP_BUILD_NUMBER,
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function putWithAuth(url, payload) {
  let currentSessionOrNull = await getCurrentSessionOrNull();

  if (currentSessionOrNull === null) {
    return null;
  }

  let response = await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + currentSessionOrNull.idToken.jwtToken,
      "Content-Type": "application/json",
      "Website-Version": process.env.REACT_APP_BUILD_NUMBER,
    },
    body: JSON.stringify(payload),
  });

  return response;
}
