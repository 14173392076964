import React from "react";
import moment from "moment";
import Score from "./Score";

export default function MatchScore(props) {
  if (props.finalScore) {
    return <Score score={props.finalScore} />;
  } else if (props.currentScore) {
    return (
      <span>
        <Score score={props.currentScore} /> (L)
      </span>
    );
  } else {
    return <span>{moment.utc(props.kickoff).local().format("HH:mm")}</span>;
  }
}
