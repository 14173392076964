import React from "react";
import { Link } from "react-router-dom";
import linq from "linq";
import Player from "./Player";
import { showPlayersOptions } from "./ShowPlayersOption";
import Name from "./Name";

function LeaderboardHeaderRow() {
  return (
    <tr>
      <th>#</th>
      <th>Player</th>
      <th className="text-center">
        <Name
          name="Predicted"
          shortName={<abbr title="Matches predicted">MP</abbr>}
          breakpoint="sm"
        />
      </th>
      <th className="text-center">
        <Name
          name="Points"
          shortName={<abbr title="Points">PTS</abbr>}
          breakpoint="sm"
        />
      </th>
      <th className="text-center">
        <Name
          name="Average"
          shortName={<abbr title="Average">AVG</abbr>}
          breakpoint="sm"
        />
      </th>
    </tr>
  );
}

function LeaderboardRow(props) {
  const trClassName = props.leaderboardItem.player.isMe ? "table-info" : null;

  const linkDestination = props.getPlayerLinkDestination(
    props.leaderboardItem.player.id
  );

  const playerCellContents = linkDestination ? (
    <Link to={linkDestination}>
      <Player player={props.leaderboardItem.player} />
    </Link>
  ) : (
    <Player player={props.leaderboardItem.player} />
  );

  return (
    <tr className={trClassName}>
      <td>
        {props.leaderboardItem.rank}
        {props.leaderboardItem.rankIsShared ? "=" : null}
      </td>
      <td>{playerCellContents}</td>
      <td className="text-center">{props.leaderboardItem.matchesPredicted}</td>
      <td className="text-center">{props.leaderboardItem.totalPoints}</td>
      <td className="text-center">
        {props.leaderboardItem.pointsPerMatch.toFixed(2)}
      </td>
    </tr>
  );
}

export default function Leaderboard(props) {
  const getLeaderboardToUse = () => {
    switch (props.showPlayersOption) {
      case showPlayersOptions.ALL:
        return props.leaderboard;
      case showPlayersOptions.ONLY_HUMANS:
        return props.humansLeaderboard;
      case showPlayersOptions.ONLY_BOTS:
        return props.botsLeaderboard;
      default:
        return null;
    }
  };

  const leaderboardToUse = getLeaderboardToUse();

  const rows = linq
    .from(leaderboardToUse)
    .select((li) => (
      <LeaderboardRow
        key={li.player.id}
        leaderboardItem={li}
        getPlayerLinkDestination={props.getPlayerLinkDestination}
      />
    ))
    .toArray();

  return (
    <>
      <table className="table table-sm table-striped table-hover">
        <tbody>
          <LeaderboardHeaderRow />
          {rows}
        </tbody>
      </table>
    </>
  );
}
