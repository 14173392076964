import React from "react";
import { getMatchOverview } from "../PtoApi";
import TeamImage from "../Components/TeamImage";
import MatchScore from "../Components/MatchScore";
import linq from "linq";
import Skeleton from "react-loading-skeleton";
import { getPredictionResultClass } from "../Utils";
import Score from "../Components/Score";
import Points from "../Components/Points";
import Player from "../Components/Player";
import moment from "moment";
import ShowPlayersOption, {
  showPlayersOptions,
} from "../Components/ShowPlayersOption";
import { Link } from "react-router-dom";
import Name from "../Components/Name";
import Nav from "../Components/Nav";
import { getRoundPredictionsUrl } from "../Urls";
import Main from "../Components/Main";

const PredictedResultRow = (props) => {
  return (
    <tr>
      <td>{props.title}</td>
      <td className="text-center">{props.matchResultCounts.count}</td>
      <td className="text-center">
        {props.matchResultCounts.percentage.toFixed(1)}&nbsp;%
      </td>
    </tr>
  );
};

const PredictedResultSummary = (props) => {
  return (
    <div className="row">
      <div className="col col-lg-6">
        <table className="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <th colspan="3" className="text-center">
                Predicted Result Summary
              </th>
            </tr>
          </thead>
          <tbody>
            <PredictedResultRow
              title={props.match.homeTeam.name}
              matchResultCounts={props.predictedResultSummary.homeWin}
            />
            <PredictedResultRow
              title="Draw"
              matchResultCounts={props.predictedResultSummary.draw}
            />
            <PredictedResultRow
              title={props.match.awayTeam.name}
              matchResultCounts={props.predictedResultSummary.awayWin}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

function PlayerPrediction(props) {
  const predictionResultClass = getPredictionResultClass(
    props.playerPrediction.predictionResult
  );
  const predictedScore = props.playerPrediction.predictedScore ? (
    <Score score={props.playerPrediction.predictedScore} />
  ) : (
    <span>?</span>
  );
  const trClassName = props.playerPrediction.player.isMe ? "table-info" : null;

  return (
    <tr className={trClassName}>
      <td>
        <Link
          to={getRoundPredictionsUrl(
            props.round.code,
            props.playerPrediction.player.id
          )}
        >
          <Player player={props.playerPrediction.player} />
        </Link>
      </td>
      <td className="text-center">{predictedScore}</td>
      <td className={`font-weight-bold text-center ${predictionResultClass}`}>
        <Points points={props.playerPrediction.points} />
      </td>
    </tr>
  );
}

class PlayerPredictions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlayersOption: "ALL",
    };
  }

  onShowPlayersOptionChange(value) {
    this.setState({ showPlayersOption: value });
  }

  shouldShow(player) {
    switch (this.state.showPlayersOption) {
      case showPlayersOptions.ALL:
        return true;
      case showPlayersOptions.ONLY_HUMANS:
        return player.isHuman;
      case showPlayersOptions.ONLY_BOTS:
        return !player.isHuman;
      default:
        return null;
    }
  }

  render() {
    const rows = linq
      .from(this.props.playerPredictions)
      .where((pp) => this.shouldShow(pp.player))
      .orderBy((pp) => pp.player.name)
      .select((pp) => (
        <PlayerPrediction
          key={pp.player.id}
          playerPrediction={pp}
          round={this.props.round}
        />
      ))
      .toArray();

    return (
      <div className="row">
        <div className="col col-lg-6">
          {this.props.tournament.botsAllowed ? (
            <ShowPlayersOption
              onSetValue={(v) => this.onShowPlayersOptionChange(v)}
            />
          ) : null}
          <table className="table table-sm table-striped table-hover ">
            <thead>
              <tr>
                <th colSpan="3" className="text-center">
                  Predictions
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

function MatchHeader(props) {
  return (
    <div className="row my-3">
      <div className="col-4 col-sm-5 text-center h4">
        <div className="text-center float-sm-left">
          <TeamImage team={props.match.homeTeam} height="50" />
        </div>

        <Name
          breakpoint="md"
          name={props.match.homeTeam.name}
          shortName={props.match.homeTeam.shortName}
        />
      </div>

      <div className="col-4 col-sm-2 text-center">
        <div className="h3 font-weight-bold">
          <MatchScore
            currentScore={props.match.currentScore}
            finalScore={props.match.finalScore}
            kickoff={props.match.kickoff}
          />
        </div>
        <div className="text-muted">
          {moment.utc(props.match.kickoff).local().format("dddd DD MMMM")}
        </div>
      </div>

      <div className="col-4 col-sm-5 text-center h4">
        <div className="text-center float-sm-right">
          <TeamImage team={props.match.awayTeam} height="50" />
        </div>

        <Name
          breakpoint="md"
          name={props.match.awayTeam.name}
          shortName={props.match.awayTeam.shortName}
        />
      </div>
    </div>
  );
}

class MatchOverviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      matchOverview: null,
    };
  }

  load = async () => {
    try {
      this.setState({ isLoading: true });

      let response = await getMatchOverview(this.props.matchId);

      let json = await response.json();

      this.setState({
        matchOverview: json,
      });
    } catch (err) {
      this.setState({ result: "Error!" });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  async componentDidMount() {
    await this.load();
  }

  async componentWillReceiveProps(newProps) {
    this.props = newProps;
    await this.load();
  }

  render() {
    return (
      <>
        <Nav onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          {this.state.matchOverview ? (
            <MatchHeader match={this.state.matchOverview.match} />
          ) : (
            <Skeleton />
          )}
          {this.state.matchOverview ? (
            this.state.matchOverview.predictedResultSummary ? (
              <PredictedResultSummary
                match={this.state.matchOverview.match}
                predictedResultSummary={
                  this.state.matchOverview.predictedResultSummary
                }
              />
            ) : null
          ) : (
            <Skeleton />
          )}
          {this.state.matchOverview ? (
            <PlayerPredictions
              playerPredictions={this.state.matchOverview.playerPredictions}
              round={this.state.matchOverview.round}
              tournament={this.state.matchOverview.tournament}
            />
          ) : (
            <Skeleton />
          )}
        </Main>
      </>
    );
  }
}

export default MatchOverviewPage;
