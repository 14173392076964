import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import linq from "linq";
import DateTimePicker from "react-datetime-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";

import Nav, { navPages } from "../Components/Nav";
import RoundsDropdown from "../Components/RoundsDropdown";
import TournamentsDropdown from "../Components/TournamentsDropdown";
import SavePlaceholder from "../Components/SavePlaceholder";
import {
  getAdminEditMatchesPage,
  editMatchHomeTeam,
  editMatchAwayTeam,
  editMatchKickoff,
} from "../PtoApi";
import { getAdminEditMatchesUrl } from "../Urls";
import Main from "../Components/Main";
import TeamImage from "../Components/TeamImage";

const TeamSelector = (props) => {
  const onChange = (event) => {
    const newlySelectedTeamId = event.target.value;
    props.onTeamSelected(newlySelectedTeamId);
  };

  return (
    <select
      className="custom-select"
      defaultValue={props.teamId}
      onChange={onChange}
    >
      {linq
        .from(props.teams)
        .orderBy((t) => (t.id === 0 ? 0 : 1)) // Put placeholder team at the top
        .thenBy((t) => t.name)
        .select((t) => (
          <option key={t.id} value={t.id}>
            {t.name}
          </option>
        ))
        .toArray()}
    </select>
  );
};

const TeamSuggestion = (props) => {
  return (
    <div className="col-12 col-sm-3 mb-1 text-info">
      <FontAwesomeIcon icon={faPenSquare} className="mr-2" />
      {props.teamType}
      <span className="mx-2">
        <TeamImage team={props.team} height="16" />
      </span>
      {props.team.name}
    </div>
  );
};

const Match = (props) => {
  const [saveStatus, setSaveStatus] = useState(null);
  const [error, setError] = useState(null);

  const save = async (doSave) => {
    try {
      setSaveStatus("SAVING");
      setError(null);

      const response = await doSave();

      if (response.ok) {
        setSaveStatus("SAVED");
        setError(null);
      } else {
        const json = await response.json();

        setSaveStatus("FAILED");
        setError(json.errors[0].message);
      }
    } catch (err) {
      setSaveStatus("FAILED");
      setError(err.message);
    }
  };

  const onHomeTeamChange = async (homeTeamId) => {
    await save(async () => await editMatchHomeTeam(props.match.id, homeTeamId));
  };

  const onAwayTeamChange = async (awayTeamId) => {
    await save(async () => await editMatchAwayTeam(props.match.id, awayTeamId));
  };

  const onKickoffChange = async (kickoffLocal) => {
    const kickoffUtc = kickoffLocal.toISOString();

    await save(async () => await editMatchKickoff(props.match.id, kickoffUtc));
  };

  const matchUpdate = linq
    .from(props.matchUpdates)
    .singleOrDefault((mu) => mu.matchId === props.match.id);

  const homeTeamSuggestion =
    matchUpdate !== null && matchUpdate.homeTeam !== null ? (
      <TeamSuggestion teamType="HOME" team={matchUpdate.homeTeam.newValue} />
    ) : null;
  const awayTeamSuggestion =
    matchUpdate !== null && matchUpdate.awayTeam !== null ? (
      <TeamSuggestion teamType="AWAY" team={matchUpdate.awayTeam.newValue} />
    ) : null;

  return (
    <div className="row mt-3">
      <div className="col-12 col-sm-3 mb-1">
        <TeamSelector
          teams={props.teams}
          teamId={props.match.homeTeam.id}
          onTeamSelected={onHomeTeamChange}
        />
      </div>
      <div className="col-12 col-sm-3 mb-1">
        <TeamSelector
          teams={props.teams}
          teamId={props.match.awayTeam.id}
          onTeamSelected={onAwayTeamChange}
        />
      </div>
      <div className="col-10 col-sm-3 mb-1">
        <DateTimePicker
          format="dd MMM y HH:mm"
          onChange={onKickoffChange}
          value={new Date(props.match.kickoff)}
        />
      </div>
      <div className="col-2 col-sm-3">
        <SavePlaceholder status={saveStatus} error={error} />
      </div>
      {homeTeamSuggestion}
      {awayTeamSuggestion}
    </div>
  );
};

export default function AdminEditMatchesPage(props) {
  const [tournaments, setTournaments] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [rounds, setRounds] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);
  const [matches, setMatches] = useState(null);
  const [teams, setTeams] = useState(null);
  const [matchUpdates, setMatchUpdates] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setTournaments(null);
      setSelectedTournament(null);
      setRounds(null);
      setSelectedRound(null);
      setMatches(null);
      setTeams(null);
      setMatchUpdates(null);

      const response = await getAdminEditMatchesPage(props.roundCode);
      const json = await response.json();

      setTournaments(json.tournaments);
      setSelectedTournament(json.selectedTournament);
      setRounds(json.rounds);
      setSelectedRound(json.selectedRound);
      setMatches(json.matches);
      setTeams(json.teams);
      setMatchUpdates(json.matchUpdates);
    };

    fetchData();
  }, [props.roundCode]);

  return (
    <>
      <Nav
        currentPage={navPages.ADMIN}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <div className="row">
          <div className="col-6">
            {tournaments && selectedTournament ? (
              <TournamentsDropdown
                tournaments={tournaments}
                tournament={selectedTournament}
                getLinkDestination={(t) =>
                  getAdminEditMatchesUrl(t.currentRoundCode)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
          <div className="col-6">
            {rounds && selectedRound ? (
              <RoundsDropdown
                rounds={rounds}
                round={selectedRound}
                includeOverall={false}
                getRoundLinkDestination={(roundCode) =>
                  getAdminEditMatchesUrl(roundCode)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        <div>
          {matches && teams ? (
            linq
              .from(matches)
              .orderBy((m) => m.kickoff)
              .thenBy((m) => m.homeTeam.name)
              .select((m) => (
                <Match
                  key={m.id}
                  match={m}
                  teams={teams}
                  matchUpdates={matchUpdates}
                />
              ))
              .toArray()
          ) : (
            <Skeleton count={5} />
          )}
        </div>
      </Main>
    </>
  );
}
