import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, Redirect } from "react-router-dom";
import linq from "linq";
import Name from "../Components/Name";
import Score from "../Components/Score";
import MatchScore from "../Components/MatchScore";
import { getPredictionsPage, getUserPlayer, postPredictions } from "../PtoApi";
import { getPredictionResultClass } from "../Utils";
import {
  getAboutUrl,
  getCreatePlayerUrl,
  getMatchUrl,
  getRoundPredictionsUrl,
} from "../Urls";
import Points from "../Components/Points";
import TournamentsDropdown from "../Components/TournamentsDropdown";
import RoundsDropdown from "../Components/RoundsDropdown";
import { formatDate } from "../Strings";
import SavePlaceholder from "../Components/SavePlaceholder";
import PlayersDropdown from "../Components/PlayersDropdown";
import Nav, { navPages } from "../Components/Nav";
import Main from "../Components/Main";
import TeamDisplay from "../Components/TeamDisplay";
import Alert, { alertLevels } from "../Components/Alert";
import Euro2024CharityAlert from "../Components/Euro2024CharityAlert";

const ExtraTimeAlert = (props) => {
  if (props.roundCode === "euro2024-R16") {
    return (
      <Alert level={alertLevels.INFORMATION}>
        Any goals scored in extra time count towards the final score, but
        penalties in a shootout do not. For full rules, visit the{" "}
        <Link className="alert-link" to={getAboutUrl()}>
          about page
        </Link>
        .
      </Alert>
    );
  }

  return null;
};

const CharityAlert = (props) => {
  if (props.roundCode === "euro2024-GS1") {
    return <Euro2024CharityAlert />;
  }

  return null;
};

const PredictionEntry = (props) => {
  const [saveStatus, setSaveStatus] = useState(null);
  const [error, setError] = useState(null);

  const [home, setHome] = useState(
    props.predictedScore ? props.predictedScore.home : null
  );
  const [away, setAway] = useState(
    props.predictedScore ? props.predictedScore.away : null
  );

  const saveIfValid = async (score) => {
    if (score.home !== null && score.away !== null) {
      try {
        setSaveStatus("SAVING");
        setError(null);

        const response = await postPredictions(
          props.playerId,
          props.matchId,
          score.home,
          score.away
        );

        if (response.ok) {
          setSaveStatus("SAVED");
          setError(null);
        } else {
          const json = await response.json();

          setSaveStatus("FAILED");
          setError(json.errors[0].message);
        }
      } catch (err) {
        setSaveStatus("FAILED");
        setError(err.message);
      }
    }
  };

  const onHomeChange = async (e) => {
    const parsedHome = parseInt(e.target.value);
    const newHome = isNaN(parsedHome) ? null : parsedHome;

    setHome(newHome);

    await saveIfValid({ home: newHome, away: away });
  };

  const onAwayChange = async (e) => {
    const parsedAway = parseInt(e.target.value);
    const newAway = isNaN(parsedAway) ? null : parsedAway;

    setAway(newAway);

    await saveIfValid({ home: home, away: newAway });
  };

  return (
    <>
      <div className="form-row">
        <div className="col-6 text-center">
          <input
            type="number"
            className="form-control"
            min="0"
            max="10"
            onChange={(e) => onHomeChange(e)}
            defaultValue={props.predictedScore?.home}
          />
        </div>
        <div className="col-6 text-center">
          <input
            type="number"
            className="form-control"
            min="0"
            max="10"
            onChange={(e) => onAwayChange(e)}
            defaultValue={props.predictedScore?.away}
          />
        </div>
      </div>
      <div className="text-center">
        <SavePlaceholder status={saveStatus} error={error} />
      </div>
    </>
  );
};

const PredictedScore = (props) => {
  if (props.predictionStatus === "HIDDEN") {
    return <span>?</span>;
  }

  if (props.predictionStatus === "EDITABLE") {
    return (
      <PredictionEntry
        playerId={props.playerId}
        matchId={props.matchId}
        predictedScore={props.predictedScore}
      />
    );
  }

  if (props.predictionStatus === "FIXED") {
    return props.predictedScore ? (
      <Score score={props.predictedScore} />
    ) : (
      <span>(none)</span>
    );
  }

  return null;
};

const TeamName = (props) => {
  return (
    <Name
      name={props.team.name}
      shortName={props.team.shortName}
      breakpoint="sm"
    />
  );
};

const MatchRow = (props) => {
  return (
    <tr>
      <td className="align-middle">
        <TeamDisplay
          team={props.matchPrediction.match.homeTeam}
          teamName={<TeamName team={props.matchPrediction.match.homeTeam} />}
        />
      </td>

      <td className="text-center align-middle font-weight-bold">
        <PredictedScore
          playerId={props.playerId}
          matchId={props.matchPrediction.match.id}
          predictionStatus={props.matchPrediction.predictionStatus}
          predictedScore={props.matchPrediction.predictedScore}
        />
      </td>

      <td className="align-middle">
        <TeamDisplay
          team={props.matchPrediction.match.awayTeam}
          teamName={<TeamName team={props.matchPrediction.match.awayTeam} />}
        />
      </td>

      <td className="text-center align-middle">
        <Link to={getMatchUrl(props.matchPrediction.match.id)}>
          <MatchScore
            currentScore={props.matchPrediction.match.currentScore}
            finalScore={props.matchPrediction.match.finalScore}
            kickoff={props.matchPrediction.match.kickoff}
          />
        </Link>
      </td>

      <td
        className={`text-center align-middle ${getPredictionResultClass(
          props.matchPrediction.predictionResult
        )}`}
      >
        <Points points={props.matchPrediction.points} />
      </td>
    </tr>
  );
};

const MatchGroupHeaderRow = (props) => {
  return (
    <tr>
      <th colSpan="5">{formatDate(props.date)}</th>
    </tr>
  );
};

const MatchGroup = (props) => {
  const matchRows = linq
    .from(props.matchPredictions)
    .orderBy((mp) => mp.match.kickoff)
    .thenBy((mp) => mp.match.homeTeam.name)
    .select((mp) => (
      <MatchRow
        key={mp.match.id}
        playerId={props.playerId}
        matchPrediction={mp}
      />
    ))
    .toArray();

  return (
    <>
      <MatchGroupHeaderRow date={props.date} />
      {matchRows}
    </>
  );
};

const HeaderRow = (props) => {
  return (
    <tr className="text-center">
      <th>&nbsp;</th>
      <th>
        <Name name="Predicted" shortName="Pred." breakpoint="sm" />
      </th>
      <th>&nbsp;</th>
      <th>
        <Name name="Actual" shortName="Act." breakpoint="sm" />
      </th>
      <th>&nbsp;</th>
    </tr>
  );
};

const TotalRow = (props) => {
  return (
    <tr>
      <th colSpan="4">Total</th>
      <td className="text-center">
        <Points points={props.totalPoints} />
      </td>
    </tr>
  );
};

export default function PredictionsPage(props) {
  const [predictionsPageData, setPredictionsPageData] = useState(null);
  const [userHasPlayer, setUserHasPlayer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setPredictionsPageData(null);

      let playerId;
      if (!props.playerId) {
        const playerResponse = await getUserPlayer();

        if (playerResponse.ok) {
          const playerJson = await playerResponse.json();
          playerId = playerJson.id;
          setUserHasPlayer(true);
        } else {
          setUserHasPlayer(false);
        }
      } else {
        playerId = props.playerId;
      }

      if (playerId) {
        const predictionsPageResponse = await getPredictionsPage(
          props.roundCode,
          playerId
        );
        const predictionsPageJson = await predictionsPageResponse.json();

        setPredictionsPageData(predictionsPageJson);
      }
    };

    fetchData();
  }, [props.roundCode, props.playerId]);

  if (userHasPlayer === false) {
    return <Redirect to={getCreatePlayerUrl()} />;
  }

  return (
    <>
      <Nav
        currentPage={navPages.PREDICTIONS}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <div className="row">
          <div className="col mb-3">
            {predictionsPageData !== null ? (
              <TournamentsDropdown
                tournaments={predictionsPageData.tournaments}
                tournament={predictionsPageData.tournament}
                getLinkDestination={(t) =>
                  getRoundPredictionsUrl(t.currentRoundCode, props.playerId)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
          <div className="col mb-3">
            {predictionsPageData !== null ? (
              <RoundsDropdown
                rounds={predictionsPageData.rounds}
                round={predictionsPageData.round}
                includeOverall={false}
                getRoundLinkDestination={(roundCode) =>
                  getRoundPredictionsUrl(roundCode, props.playerId)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
          <div className="col mb-3">
            {predictionsPageData !== null ? (
              <PlayersDropdown
                players={predictionsPageData.players}
                player={predictionsPageData.player}
                getLinkDestination={(playerId) =>
                  getRoundPredictionsUrl(props.roundCode, playerId)
                }
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        {predictionsPageData != null ? (
          <ExtraTimeAlert roundCode={predictionsPageData.round.code} />
        ) : null}
        {predictionsPageData != null ? (
          <CharityAlert roundCode={predictionsPageData.round.code} />
        ) : null}
        {predictionsPageData != null ? (
          <table className="table table-sm">
            <tbody>
              <HeaderRow />
              {linq
                .from(predictionsPageData.matchGroups)
                .orderBy((mg) => mg.date)
                .select((mg) => (
                  <MatchGroup
                    key={mg.date}
                    date={mg.date}
                    playerId={predictionsPageData.player.id}
                    matchPredictions={mg.matchPredictions}
                  />
                ))
                .toArray()}
              <TotalRow totalPoints={predictionsPageData.totalPoints} />
            </tbody>
          </table>
        ) : (
          <Skeleton count={5} />
        )}
        <div className="text-muted">
          {predictionsPageData != null ? predictionsPageData.player.bio : null}
        </div>
      </Main>
    </>
  );
}
