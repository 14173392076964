import React from "react";

export default function TeamImage(props) {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/team-images/logo-${props.team.id}.svg`}
      height={props.height}
      width={props.height}
      alt={props.team.name}
    />
  );
}
