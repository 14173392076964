import React from "react";

const getPlayerClassName = (player) => {
  let classNames = [];

  if (!player.isHuman) {
    classNames.push("player-non-human");
  }

  if (player.isMe) {
    classNames.push("player-me");
  }

  return classNames.join(" ");
};

export default function Player(props) {
  const className = getPlayerClassName(props.player);

  return <span className={className}>{props.player.name}</span>;
}
