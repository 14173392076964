import React from "react";

export default function Points(props) {
  const plural = props.points === 1 ? "" : "s";

  return (
    <span className="badge badge-primary">
      {props.points ?? "?"}&nbsp;pt{plural}
    </span>
  );
}
