import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Redirect } from "react-router-dom";
import Main from "../Components/Main";

import Nav from "../Components/Nav";
import Spinner from "../Components/Spinner";

import { getCreatePlayerPage, postUserPlayer } from "../PtoApi";
import { getRoundPredictionsUrl } from "../Urls";

export default function CreatePlayerPage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);
  const [playerName, setPlayerName] = useState("");
  const [canCreatePlayer, setCanCreatePlayer] = useState(false);
  const [isCreatingPlayer, setIsCreatingPlayer] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setIsLoading(true);
      setPlayer(null);
      setCurrentRound(null);

      const response = await getCreatePlayerPage();
      const json = await response.json();

      setPlayer(json.player);
      setCurrentRound(json.currentRound);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const onPlayerNameChange = (e) => {
    const newlySetPlayerName = e.target.value;

    setPlayerName(newlySetPlayerName);
    setCanCreatePlayer(newlySetPlayerName?.length > 0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsCreatingPlayer(true);

    try {
      const response = await postUserPlayer(playerName);
      const json = await response.json();
      setPlayer(json);
    } catch (err) {
      setIsCreatingPlayer(false);
      setError(err.message);
    }
  };

  return (
    <>
      <Nav onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        {isLoading ? (
          <Skeleton />
        ) : player && currentRound ? (
          <Redirect to={getRoundPredictionsUrl(currentRound.code, player.id)} />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                It looks like you've never entered predictions before. What's
                your name?
              </label>
              <input
                type="text"
                className="form-control"
                name="playerName"
                placeholder="Name"
                onChange={onPlayerNameChange}
                value={playerName}
              />
            </div>
            <div className="form-group">
              <input
                type="submit"
                className="btn btn-primary mr-3"
                value="Enter"
                disabled={!canCreatePlayer}
              />
              <Spinner showSpinner={isCreatingPlayer} />
            </div>
            <div className="form-group text-danger">{error}</div>
          </form>
        )}
      </Main>
    </>
  );
}
