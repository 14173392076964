import React from "react";
import { Link } from "react-router-dom";

import { getEuro2024CharityUrl } from "../Urls";
import Alert, { alertLevels } from "./Alert";

export default function Euro2024CharityAlert() {
  return (
    <Alert level={alertLevels.LIGHT}>
      This tournament has a £5 entry fee and raises money for{" "}
      <a className="alert-link" href="https://www.macmillan.org.uk/">
        Macmillan Cancer Support
      </a>
      . For more information, see the{" "}
      <Link className="alert-link" to={getEuro2024CharityUrl()}>
        charity page
      </Link>
      .
    </Alert>
  );
}
