import React from "react";
import Nav from "../Components/Nav";
import Main from "../Components/Main";
import SupportLink from "../Components/SupportLink";
import { Link } from "react-router-dom";
import { getHomeUrl } from "../Urls";

export default function NotFoundPage(props) {
  return (
    <>
      <Nav onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        <h1>404 Not Found</h1>
        <p>Oh dear! The page you were looking for doesn't exist!</p>
        <p>
          Try going back to the <Link to={getHomeUrl()}>home page</Link> and
          navigating from there.
        </p>
        <p>
          If you think there's a problem with the site, please email{" "}
          <SupportLink /> so we can fix it!
        </p>
      </Main>
    </>
  );
}
