import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Main from "../Components/Main";
import Nav from "../Components/Nav";
import TournamentHeader from "../Components/TournamentHeader";
import SupportLink from "../Components/SupportLink";
import { getCharityPage } from "../PtoApi";

export default function Euro2024CharityPage(props) {
  const [tournament, setTournament] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setTournament(null);

      const response = await getCharityPage(props.tournamentCode);
      const json = await response.json();

      setTournament(json.tournament);
    };

    fetchData();
  }, []);

  return (
    <>
      <Nav onLoggedOut={props.onLoggedOut} />
      <Main>
        {tournament ? (
          <TournamentHeader tournament={tournament} />
        ) : (
          <Skeleton />
        )}
        <p>
          The predictions game for Euro 2024 costs <strong>£5</strong> to enter.
          We expect that most people playing the game will have been invited by
          Dan or Andy Carter; if so, contact one of them to pay your entry fee.
          If not (hello!), please email <SupportLink /> and we'll let you know
          how you can pay.
        </p>

        <img
          src={`${process.env.PUBLIC_URL}/macmillan-logo.svg`}
          className="img-thumbnail mx-auto ml-sm-3 mr-sm-0 mb-3 d-block d-sm-inline float-sm-right"
          alt="Refuge logo"
        />

        <p>
          A small portion of the entry fees is set aside as a prize pot for the
          gold (£20), silver (£15) and bronze (£10) players. The rest will be
          donated to{" "}
          <a href="https://www.macmillan.org.uk/">Macmillan Cancer Support</a>,
          a charity whose purpose is to do whatever it takes to support people
          living with cancer.
        </p>
      </Main>
    </>
  );
}
