import React from "react";
import SavePlaceholder from "../Components/SavePlaceholder";
import TeamImage from "../Components/TeamImage";
import Name from "./Name";

function TeamName(props) {
  return (
    <>
      <Name
        breakpoint="sm"
        name={props.team.name}
        shortName={props.team.shortName}
      />
    </>
  );
}

export default class MatchDataEntry extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.score === null) {
      this.home = null;
      this.away = null;
    } else {
      this.home = this.props.score.home;
      this.away = this.props.score.away;
    }

    this.state = {
      saveStatus: null,
      error: null,
    };
  }

  saveIfValid = async () => {
    if (this.home !== null && this.away !== null) {
      try {
        this.setState({
          saveStatus: "SAVING",
          error: null,
        });

        let response = await this.props.onSave(
          this.props.match.id,
          this.home,
          this.away
        );

        if (response.ok) {
          this.setState({
            saveStatus: "SAVED",
            error: null,
          });
        } else {
          let json = await response.json();
          this.setState({
            saveStatus: "FAILED",
            error: json.errors[0].message,
          });
        }
      } catch (err) {
        this.setState({
          saveStatus: "FAILED",
          error: err.message,
        });
      }
    }
  };

  onHomeChange = async (e) => {
    const parsedHome = parseInt(e.target.value);
    this.home = isNaN(parsedHome) ? null : parsedHome;

    await this.saveIfValid();
  };

  onAwayChange = async (e) => {
    const parsedAway = parseInt(e.target.value);
    this.away = isNaN(parsedAway) ? null : parsedAway;

    await this.saveIfValid();
  };

  render() {
    return (
      <div className="form-row mb-3">
        <div className="col-3 col-sm-4 col-lg-3 col-xl-2 text-center">
          <TeamImage team={this.props.match.homeTeam} height="40" />
          <div>
            <TeamName team={this.props.match.homeTeam} />
          </div>
        </div>
        <div className="col-3 col-sm-2 col-lg-1 text-center">
          <input
            type="number"
            className="form-control"
            min="0"
            max="10"
            onChange={(e) => this.onHomeChange(e)}
            defaultValue={this.home}
            disabled={!this.props.isEditable}
          />
        </div>
        <div className="col-3 col-sm-2 col-lg-1 text-center">
          <input
            type="number"
            className="form-control"
            min="0"
            max="10"
            onChange={(e) => this.onAwayChange(e)}
            defaultValue={this.away}
            disabled={!this.props.isEditable}
          />
        </div>
        <div className="col-3 col-sm-4 col-lg-3 col-xl-2 text-center">
          <TeamImage team={this.props.match.awayTeam} height="40" />
          <div>
            <TeamName team={this.props.match.awayTeam} />
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="col-12 col-lg-3">
          <SavePlaceholder
            status={this.state.saveStatus}
            error={this.state.error}
          />
        </div>
      </div>
    );
  }
}
