import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { signOut } from "../PtoAuth";
import { getNav } from "../PtoApi";
import {
  getAdminUrl,
  getDefaultBreakdownUrl,
  getAccountUrl,
  getHomeUrl,
  getDefaultLeaderboardUrl,
  getDefaultPredictionsUrl,
} from "../Urls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faListOl,
  faQuestion,
  faShieldAlt,
  faSignOutAlt,
  faTable,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getCurrentSessionOrNull } from "../PtoAuth";

const NavLink = (props) => {
  const isActive =
    props.page && props.currentPage && props.page === props.currentPage;

  return (
    <li className={`nav-item${isActive ? " active" : ""}`}>
      <Link
        className="nav-link d-flex flex-row flex-md-column justify-content-end align-items-center"
        to={props.to}
      >
        <div className="order-1 order-md-0 ml-3 ml-md-0">
          <FontAwesomeIcon icon={props.icon} fixedWidth={true} />
        </div>
        <div>{props.title}</div>
      </Link>
    </li>
  );
};

const NavAction = (props) => {
  return (
    <li className="nav-item">
      <a
        className="nav-link d-flex flex-row flex-md-column justify-content-end align-items-center"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      >
        <div className="order-1 order-md-0 ml-3 ml-md-0">
          <FontAwesomeIcon icon={props.icon} fixedWidth={true} />
        </div>
        <div>{props.title}</div>
      </a>
    </li>
  );
};

const NavLinks = (props) => {
  const history = useHistory();

  const logOut = async () => {
    await signOut();
    await props.onLoggedOut();
    history.push("/login");
  };

  if (!props.isLoggedIn) {
    return null;
  }

  return (
    <>
      <NavLink
        to={getHomeUrl()}
        icon={faHome}
        title="Home"
        page={navPages.HOME}
        currentPage={props.currentPage}
      />

      <NavLink
        to={getDefaultPredictionsUrl()}
        icon={faQuestion}
        title="Predictions"
        page={navPages.PREDICTIONS}
        currentPage={props.currentPage}
      />

      <NavLink
        to={getDefaultLeaderboardUrl()}
        icon={faListOl}
        title="Leaderboard"
        page={navPages.LEADERBOARD}
        currentPage={props.currentPage}
      />

      <NavLink
        to={getDefaultBreakdownUrl()}
        icon={faTable}
        title="Breakdown"
        page={navPages.BREAKDOWN}
        currentPage={props.currentPage}
      />

      <NavLink
        to={getAccountUrl()}
        icon={faUser}
        title="Account"
        page={navPages.ACCOUNT}
        currentPage={props.currentPage}
      />

      {props.nav && props.nav.authenticatedUserIsAdmin ? (
        <NavLink
          to={getAdminUrl()}
          icon={faShieldAlt}
          title="Admin"
          page={navPages.ADMIN}
          currentPage={props.currentPage}
        />
      ) : null}

      <NavAction
        icon={faSignOutAlt}
        title="Log out"
        onClick={async () => await logOut()}
      />
    </>
  );
};

export default function Nav(props) {
  const [nav, setNav] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setNav(null);

      const currentSessionOrNull = await getCurrentSessionOrNull();

      if (currentSessionOrNull !== null) {
        setIsLoggedIn(true);
        const response = await getNav();
        setNav(await response.json());
      }
    };

    fetchData();
  }, []);

  return (
    <header>
      <nav className="navbar navbar-dark bg-primary navbar-expand-md">
        <div className="container">
          <Link className="logo" to={getHomeUrl()}>
            <img
              className="normal"
              src={`${process.env.PUBLIC_URL}/pto-logo-white.svg`}
              height="32px"
              alt="PTO logo"
            />
            <img
              className="hover"
              src={`${process.env.PUBLIC_URL}/pto-logo-green.svg`}
              height="32px"
              alt="PTO logo"
            />
            <span className="ml-3">pto.football</span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <NavLinks
                nav={nav}
                currentPage={props.currentPage}
                isLoggedIn={isLoggedIn}
                onLoggedOut={async () => await props.onLoggedOut()}
              />
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export const navPages = {
  HOME: "home",
  PREDICTIONS: "predictions",
  LEADERBOARD: "leaderboard",
  BREAKDOWN: "breakdown",
  ADMIN: "admin",
  ACCOUNT: "account",
};
