import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import Nav, { navPages } from "../Components/Nav";
import TournamentsDropdown from "../Components/TournamentsDropdown";
import SavePlaceholder from "../Components/SavePlaceholder";

import {
  getAdminRoundsPage,
  patchRound,
  postBotPredictions,
  postPremierLeagueGameweek,
} from "../PtoApi";
import { getAdminRoundsUrl } from "../Urls";
import Name from "../Components/Name";
import Main from "../Components/Main";

const EnterBotPredictionsButton = (props) => {
  const [saveStatus, setSaveStatus] = useState(null);
  const [error, setError] = useState(null);

  const enterBotPredictions = async () => {
    setSaveStatus("SAVING");
    setError(null);

    try {
      const response = await postBotPredictions(props.roundCode);

      if (response.ok) {
        setSaveStatus("SAVED");
      } else {
        setSaveStatus("FAILED");
        setError("Unknown error");
      }
    } catch (err) {
      setSaveStatus("FAILED");
      setError(err.message);
    }
  };

  return (
    <>
      <button
        className="btn btn-primary btn-sm mr-3"
        onClick={async () => await enterBotPredictions()}
      >
        Enter
      </button>
      <SavePlaceholder className="ml-3" status={saveStatus} error={error} />
    </>
  );
};

const CreatePremierLeagueGameweekSection = (props) => {
  const [gameweekNumber, setGameweekNumber] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setSaveStatus("SAVING");

      const response = await postPremierLeagueGameweek(
        props.tournamentCode,
        gameweekNumber
      );

      if (response.ok) {
        setSaveStatus("SAVED");
      } else {
        const responseJson = await response.json();
        setSaveStatus("FAILED");
        setError(responseJson.errors[0].message);
      }
    } catch (err) {
      setSaveStatus("FAILED");
    }
  };

  return (
    <form className="form-inline" onSubmit={handleSubmit}>
      <div className="form-group mr-3">
        <input
          type="number"
          className="form-control"
          name="playerName"
          placeholder="Gameweek number"
          onChange={(e) => setGameweekNumber(e.target.value)}
        />
      </div>
      <input type="submit" className="btn btn-primary mr-3" value="Create" />
      <SavePlaceholder status={saveStatus} error={error} />
    </form>
  );
};

const RoundIsActiveSwitch = (props) => {
  const [isActive, setIsActive] = useState(props.isActive);
  const [saveStatus, setSaveStatus] = useState(null);
  const [error, setError] = useState(null);

  const onIsActiveChange = async (event) => {
    setSaveStatus("SAVING");

    const value = event.target.checked;

    try {
      await patchRound(props.roundCode, value);
      setIsActive(value);
      setSaveStatus("SAVED");
    } catch (err) {
      setSaveStatus("FAILED");
      setError(err.message);
    }
  };

  const id = `isActiveCheckbox${props.roundCode}`;

  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        id={id}
        className="custom-control-input"
        defaultChecked={isActive}
        onChange={async (e) => await onIsActiveChange(e)}
      />
      <label className="custom-control-label" htmlFor={id}>
        &nbsp;
      </label>
      <SavePlaceholder status={saveStatus} error={error} />
    </div>
  );
};

const RoundRow = (props) => {
  return (
    <tr>
      <td>{props.round.name}</td>
      <td>
        <RoundIsActiveSwitch
          isActive={props.round.isActive}
          roundCode={props.round.code}
        />
      </td>
      {props.tournament.botsAllowed ? (
        <td>
          <EnterBotPredictionsButton roundCode={props.round.code} />
        </td>
      ) : null}
    </tr>
  );
};

const RoundsTable = (props) => {
  const roundRows = props.rounds.map((r) => (
    <RoundRow key={r.code} round={r} tournament={props.tournament} />
  ));

  return (
    <table className="table table-sm table-striped table-hover">
      <thead>
        <tr>
          <th>Round</th>
          <th>
            <Name name="Is active?" shortName="Active?" breakpoint="sm" />
          </th>
          {props.tournament.botsAllowed ? (
            <th>
              <Name
                name="Bot predictions"
                shortName="Bot preds"
                breakpoint="sm"
              />
            </th>
          ) : null}
        </tr>
      </thead>
      <tbody>{roundRows}</tbody>
    </table>
  );
};

export default function AdminRoundsPage(props) {
  const [tournaments, setTournaments] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [rounds, setRounds] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Clear previous values
      setTournaments(null);
      setSelectedTournament(null);
      setRounds(null);

      const response = await getAdminRoundsPage(props.tournamentCode);
      const json = await response.json();

      setTournaments(json.tournaments);
      setSelectedTournament(json.selectedTournament);
      setRounds(json.rounds);
    };

    fetchData();
  }, [props.tournamentCode]);

  return (
    <>
      <Nav
        currentPage={navPages.ADMIN}
        onLoggedOut={async () => await props.onLoggedOut()}
      />
      <Main>
        <div className="row">
          <div className="col col-md-6 mb-3">
            {tournaments && selectedTournament ? (
              <TournamentsDropdown
                tournaments={tournaments}
                tournament={selectedTournament}
                getLinkDestination={(t) => getAdminRoundsUrl(t.code)}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
        <div>
          {rounds && selectedTournament ? (
            <RoundsTable tournament={selectedTournament} rounds={rounds} />
          ) : (
            <Skeleton count={5} />
          )}
        </div>
        <div>
          {selectedTournament ? (
            <CreatePremierLeagueGameweekSection
              tournamentCode={selectedTournament.code}
            />
          ) : null}
        </div>
      </Main>
    </>
  );
}
