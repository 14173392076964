import React from "react";

function LoadingPlaceholder(props) {
  return (
    <div>
      <span
        className="spinner-border spinner-border-sm mr-1 text-primary"
        role="status"
        aria-hidden="true"
      ></span>
      Loading...
    </div>
  );
}

export default LoadingPlaceholder;
