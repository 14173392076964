import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Amplify from "aws-amplify";

Amplify.configure({
  Auth: {
    region: "eu-west-2",
    userPoolId: "eu-west-2_JwWpg0O8l",
    userPoolWebClientId: "fe5g7q4n3ftb34ckaqv0r4q05",
  },
});

ReactDOM.render(<App />, document.getElementById("root"));
