import React from "react";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/RegisterPage";
import ConfirmEmailAddressPage from "./Pages/ConfirmEmailAddressPage";
import MatchOverviewPage from "./Pages/MatchOverviewPage";
import HomePage from "./Pages/HomePage";
import AdminFinalScoresPage from "./Pages/AdminFinalScoresPage";
import PredictedTablePage from "./Pages/PredictedTablePage";
import BreakdownPage from "./Pages/BreakdownPage";
import LeaderboardPage from "./Pages/LeaderboardPage";
import Euro2024CharityPage from "./Pages/Euro2024CharityPage";
import PredictionsPage from "./Pages/PredictionsPage";
import AboutPage from "./Pages/AboutPage";
import AdminPage from "./Pages/AdminPage";
import AdminRoundsPage from "./Pages/AdminRoundsPage";
import AdminEditMatchesPage from "./Pages/AdminEditMatchesPage";
import AdminPlayersPage from "./Pages/AdminPlayersPage";
import CreatePlayerPage from "./Pages/CreatePlayerPage";
import {
  Route,
  Redirect,
  Switch,
  useParams,
  useLocation,
} from "react-router-dom";
import LoadingPlaceholder from "./Components/LoadingPlaceholder";
import NotFoundPage from "./Pages/NotFoundPage";
import AccountPage from "./Pages/AccountPage";
import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import SubmitNewPasswordPage from "./Pages/SubmitNewPasswordPage";
import { getLoginUrl } from "./Urls";

const PredictedTableWrapper = (props) => {
  let { tournamentCode, playerId } = useParams();
  return (
    <PredictedTablePage
      tournamentCode={tournamentCode}
      playerId={playerId}
      onLoggedOut={props.onLoggedOut}
    />
  );
};

const MatchOverviewWrapper = (props) => {
  let { matchId } = useParams();
  return (
    <MatchOverviewPage matchId={matchId} onLoggedOut={props.onLoggedOut} />
  );
};

const AdminWrapper = (props) => {
  return <AdminPage onLoggedOut={props.onLoggedOut} />;
};

const AdminRoundsWrapper = (props) => {
  const tournamentCode = new URLSearchParams(useLocation().search).get(
    "tournamentCode"
  );
  return (
    <AdminRoundsPage
      tournamentCode={tournamentCode}
      onLoggedOut={props.onLoggedOut}
    />
  );
};

const AdminEditMatchesWrapper = (props) => {
  const roundCode = new URLSearchParams(useLocation().search).get("roundCode");
  return (
    <AdminEditMatchesPage
      roundCode={roundCode}
      onLoggedOut={props.onLoggedOut}
    />
  );
};

const ConfirmEmailAddressWrapper = (props) => {
  const emailAddress = new URLSearchParams(useLocation().search).get(
    "emailAddress"
  );
  return <ConfirmEmailAddressPage emailAddress={emailAddress} />;
};

const LeaderboardWrapper = (props) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  const tournamentCode = urlSearchParams.get("tournamentCode");
  const roundCode = urlSearchParams.get("roundCode");

  return (
    <LeaderboardPage
      tournamentCode={tournamentCode}
      roundCode={roundCode}
      onLoggedOut={props.onLoggedOut}
    />
  );
};

const AdminFinalScoresWrapper = (props) => {
  const roundCode = new URLSearchParams(useLocation().search).get("roundCode");

  return (
    <AdminFinalScoresPage
      roundCode={roundCode}
      onLoggedOut={props.onLoggedOut}
    />
  );
};

const AdminPlayersWrapper = (props) => {
  return <AdminPlayersPage onLoggedOut={props.onLoggedOut} />;
};

const CreatePlayerWrapper = (props) => {
  return <CreatePlayerPage onLoggedOut={props.onLoggedOut} />;
};

const BreakdownWrapper = (props) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const roundCode = urlSearchParams.get("roundCode");

  return (
    <BreakdownPage roundCode={roundCode} onLoggedOut={props.onLoggedOut} />
  );
};

const PredictionsWrapper = (props) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);

  const roundCode = urlSearchParams.get("roundCode");
  const playerId = urlSearchParams.get("playerId");

  return (
    <PredictionsPage
      roundCode={roundCode}
      playerId={playerId}
      onLoggedOut={props.onLoggedOut}
    />
  );
};

const LoginWrapper = (props) => {
  const emailAddress = new URLSearchParams(useLocation().search).get(
    "emailAddress"
  );
  const emailJustConfirmed = new URLSearchParams(useLocation().search).get(
    "emailJustConfirmed"
  );

  return (
    <LoginPage
      emailAddress={emailAddress}
      emailJustConfirmed={emailJustConfirmed}
      onLoggedIn={async () => await props.onLoggedIn()}
    />
  );
};

const RegisterWrapper = (props) => {
  const emailAddress = new URLSearchParams(useLocation().search).get(
    "emailAddress"
  );

  return <RegisterPage emailAddress={emailAddress} />;
};

const ForgotPasswordWrapper = (props) => {
  const emailAddress = new URLSearchParams(useLocation().search).get(
    "emailAddress"
  );

  return <ForgotPasswordPage emailAddress={emailAddress} />;
};

const SubmitNewPasswordWrapper = (props) => {
  const emailAddress = new URLSearchParams(useLocation().search).get(
    "emailAddress"
  );

  return <SubmitNewPasswordPage emailAddress={emailAddress} />;
};

export default function PageBody(props) {
  if (props.isLoading) {
    return (
      <div className="container">
        <LoadingPlaceholder />
      </div>
    );
  }

  if (!props.isLoggedIn) {
    return (
      <Switch>
        <Route exact path="/login">
          <LoginWrapper onLoggedIn={async () => await props.onLoggedIn()} />
        </Route>
        <Route exact path="/register">
          <RegisterWrapper />
        </Route>
        <Route exact path="/confirmEmailAddress">
          <ConfirmEmailAddressWrapper />
        </Route>
        <Route exact path="/forgotPassword">
          <ForgotPasswordWrapper />
        </Route>
        <Route exact path="/submitNewPassword">
          <SubmitNewPasswordWrapper />
        </Route>
        <Route exact path="/about" children={<AboutPage />} />
        <Route path="*">
          <Redirect to={getLoginUrl(null, false)} />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/login" children={<Redirect to="/" />} />
      <Route
        exact
        path="/admin"
        children={<AdminWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/admin/rounds"
        children={<AdminRoundsWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/admin/editMatches"
        children={<AdminEditMatchesWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/admin/finalScores"
        children={<AdminFinalScoresWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/admin/players"
        children={<AdminPlayersWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/createPlayer"
        children={<CreatePlayerWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/leaderboard"
        children={<LeaderboardWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/breakdown"
        children={<BreakdownWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/tournaments/:tournamentCode/players/:playerId"
        children={<PredictedTableWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/tournaments/euro2024/charity"
        children={
          <Euro2024CharityPage
            tournamentCode="euro2024"
            onLoggedOut={props.onLoggedOut}
          />
        }
      />
      <Route
        exact
        path="/matches/:matchId"
        children={<MatchOverviewWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/predictions"
        children={<PredictionsWrapper onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/account"
        children={<AccountPage onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/about"
        children={<AboutPage onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        exact
        path="/"
        children={<HomePage onLoggedOut={props.onLoggedOut} />}
      />
      <Route
        path="*"
        children={<NotFoundPage onLoggedOut={props.onLoggedOut} />}
      />
    </Switch>
  );
}
