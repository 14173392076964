import React from "react";
import Name from "../Components/Name";
import { Dropdown, DropdownItem } from "../Components/Dropdown";

const TournamentName = (props) => {
  return (
    <Name
      name={props.tournament.name}
      shortName={props.tournament.shortName}
      breakpoint="sm"
    />
  );
};

export default function TournamentsDropdown(props) {
  return (
    <Dropdown title={<TournamentName tournament={props.tournament} />}>
      {props.tournaments.map((t) => (
        <DropdownItem
          key={t.code}
          active={t.code === props.tournament.code}
          linkDestination={props.getLinkDestination(t)}
        >
          <TournamentName tournament={t} />
        </DropdownItem>
      ))}
    </Dropdown>
  );
}
